@use 'src/styles/utils' as *;

.shareButton {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 40px;
  padding: 8px;
  color: rgb(255 255 255 / 70%);
  background-color: transparent;
  border-left: 2px solid #282829;
  @include breakpoint('lg') {
    width: 32px;
    height: 32px;
    background-color: #282829;
    border-left: none;
    }

  &:hover {
    background-color: #373838;
    cursor: pointer;
  }

  & .isMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 48px;
    padding: 0 18px;
    background: rgb(255 255 255 / 5%);

    span {
      color: #fff;
      font-weight: normal;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }

  & .shareIcon {
    display: flex;
    align-items: center;
  }

  & .children {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    font-size: 16px;
    white-space: nowrap;

    & .noIcon {
      margin: 0;
    }
  }
}
