.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 1300px;
  padding: 90px 20px;
  font-style: normal;
  @media (max-width: 1024px) {
    min-height: auto;
    padding: 16px;
  }
}

.headercontainer {
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.header {
  margin: 0 0 40px;
  color: #fff;
  font-weight: normal;
  font-size: 36px;
  line-height: 140.62%;
}

.description {
  max-width: 543px;
  margin-bottom: 80px;
  color: rgb(255 255 255 / 70%);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
