@use 'src/styles/utils' as *;

.widget {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
  background: #121212;
  transition: flex-grow 0.5s, flex-shrink 0.5s;
}

.widgetOpen {
  flex-grow: 1;
  flex-shrink: 1;
  transition: flex-grow 0.5s, flex-shrink 0.5s;

}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 12px;
  background: #3B3B3B;
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 4px;
  overflow: auto;
  @include breakpoint('lg') {
   overflow: unset ;
 }
  
}

.content::-webkit-scrollbar {
  width: 6px;
}

.content::-webkit-scrollbar-thumb {
  background: #3E4141;
  border-radius: 999px;
}

.closeIcon{
  @include breakpoint('lg') {
    display: none !important;
  }
}

.headerLogo{
  display: none;
  @include breakpoint('lg') {
    display: block;
    width: 20px;
    height: 20px;
  }
}