.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
}

.part {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 0;
  border-top: 1px solid rgb(255 255 255 / 20%);

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partheader {
  margin: 0;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.icon {
  min-width: 20px;
  height: 20px;
  margin-bottom: 24px;
  transition: transform 0.3s;
}

.isOpen {
  transform: rotate(180deg);
}

.linksPart {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.linkItem {
  margin-bottom: 16px;
}

.linkHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.link {
  margin-bottom: 12px;
  color: #84c47c;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.content {
  display: none; /* Initially hidden */
  padding: 12px 0; /* Adds space around expanded content */
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  background-color: rgb(255 255 255 / 5%); /* Optional subtle background */
  border-radius: 8px; /* Rounds the content box edges */
}

.linkItem .content {
  display: block; /* Display content when its parent is open */
}
