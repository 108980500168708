.item {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  border-bottom: 1px solid #2D2D2D;
}

.titleItem a {
  font-weight: 500;
  font-size: 15px;

  &:hover {
    opacity: 0.8;
  }
}

.info {
  display: flex;
  flex-direction: row;
}

.name {
  margin-right: 10px;
  color: #84c47c;
  font-weight: 500;
  font-size: 13px;
}

.latestName {
  margin-right: 10px;
  color: orange;
  font-weight: 500;
  font-size: 13px;
}

.latestName:hover {
  cursor: pointer;
}

.date {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
}

.readNow {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
}

.readNow:hover {
  cursor: pointer;
}