@use 'src/styles/utils' as *;

.contentPage {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 8px;
  }
}

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.mapbox {
  position: relative;
  flex: 1;
  height: 90vh;
  background-color: #1d1f1e;
}

.filters {
  position: relative;
}

.seoTags {
  display: none;
}

.newsButton {
  z-index: 50;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  height: 32px;
  margin: auto;
  padding: 9px;
  color: rgb(255 255 255 / 70%);
  background-color: var(--color-surface-600);
  border: 0;
}

.newsButtonContainer {
  display: none;
  @include breakpoint('lg') {
    position: absolute;
    bottom: 1%;
    display: flex;
    width: 100%;
  }
}

.buttons {
  position: absolute;
  top: 3%;
  right: 3%;
  z-index: 99;
  display: none;
  width: 44px;
  height: auto;
  background-color: #1d1f1e;

  @include breakpoint('lg') {
    display: flex;
  }
}

.sidebarContainer {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
}

.adSidebar {
  position: sticky;
  top: 20px;
  width: 160px;
  height: fit-content;
  margin-top: 300px;
  padding: 0;
}

.tableView {
  flex-grow: 0;
  max-width: 100%;
  margin-left: 50px;
}

.faqs {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.contentBlock {
  max-width: 1320px;
  padding: 20px;
}

.contentBlock h4 {
  font-weight: 600;
}
