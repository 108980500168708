.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 50%);
}

.header {
  margin: 0;
  padding: 10px;
  font-size: 24px;
}

.iframe form {
  margin: 0;
  padding: 0;
}

.popup {
  position: relative;
  width: 80%;
  max-width: 600px;
  height: 80%;
  max-height: 500px;
  padding: 20px;
  background: rgb(29 29 30);
  border-radius: 8px;
}

.closeIcon {
  position: absolute;
  padding: 10px;
  cursor: pointer;
}

.signUp {
  position: relative;
  height: 45px;
  margin-bottom: 10px;
  padding: 10px;
  overflow: hidden; /* Ensures text stays within the container */
  color: black;
  font-size: 15px;
  white-space: nowrap; /* Prevents text from wrapping */
  text-align: center;
  background: var(--color-primary-lightgreen);
}

.signUp:hover {
  cursor: pointer;
}

.rightSidebar {
  display: flex;
  flex-direction: column;
  width: 14px;
  margin-top: 20px;
  background: #2d2d2d;
  transition: width 0.5s;
}

.rightSidebarOpen {
  width: 400px;
  margin: 3px;
  margin-top: 20px;
  padding: 10px;
  transition: width 0.5s;
}

.rightSidebarButtom {
  position: relative;
  top: 50%;
  left: 14px;
  min-width: 20px;
  height: 20px;
  margin-left: -18px;
  cursor: pointer;
  transition: right 0.5s;
}

.widgetsContainer {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.buttonFooter {
  display: flex;
  justify-content: center;
  width: 52px;
  height: 32px;
  padding: 5px;
  background: #3b3b3b;
  cursor: pointer;
}

.signUpText {
  display: flex;
  gap: 5px;
}

.pointsContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pointsText {
  margin: 0;
  color: transparent;
  font-size: 15px;
  background: linear-gradient(
      118deg,
      rgb(255 255 255 / 0%) 39.31%,
      #cdffcd 71.03%
    ),
    var(--primary-1, #7ec17e);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Optional: Enhance text rendering */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border: 1px solid #cdffcd;
  border-radius: 10px;
}
