.container {
  display: flex;
  align-items: center;
  width: 100%;

  .additionalInfo {
    display: block;
    color: #b0b0b0;
    width: 100%;
    font-size: 14px;
    line-height: 1;
    padding: 0 26px;
  }

  @media (max-width: 1024px) {
    flex-wrap: nowrap;
    padding: 0 8px;
  }

  .rightContainer {
    display: flex;

    @media (max-width: 1024px) {
      justify-content: flex-end;
      width: 100%;
    }

    .button {
      outline: none;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 8px 8px 7px 10px;
      margin-left: 5px;
      white-space: nowrap;

      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      border-radius: 2px;
      cursor: pointer;
      background-color: #2a2b2b;
      transition: background-color 0.2s;

      &:hover {
        @media (min-width: 1024px) {
          background-color: rgba(60, 61, 61, 0.7);
        }
      }

      &:focus {
        outline: none;
      }

      .buttonArrowLeft {
        margin-right: 5px;
      }

      .buttonArrowRight {
        margin-left: 5px;
      }
    }

    .buttonAll {
      padding: 8px 25px 7px 26px;
    }
  }
}
.left {
  justify-content: space-between;
}

.right {
  justify-content: flex-end;
}