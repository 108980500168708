.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(25px);
}

.overlayContent {
  position: relative;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  color: #fff;
  text-align: center;
  background-color: #081018;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.overlayTitle {
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
}

.overlayText {
  margin-bottom: 10px;
  color: #fff;
  font-size: 1rem;
}

.upgrade {
  color: #7ec17e;
}

/* Update class from .cardContainer to .cardsContainer */
.cardsContainer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
}

.card {
  padding: 15px;
  text-align: center;
  background: linear-gradient(
    142deg,
    rgb(245 247 250 / 12%) 21.48%,
    rgb(245 247 250 / 6%) 49.34%,
    rgb(245 247 250 / 1%) 77.2%
  );
  border: 0.766px solid rgb(245 247 250 / 12%);
  border-radius: 18.385px;
  box-shadow: 0 3.064px 3.064px 0 rgb(0 0 0 / 25%),
    6.128px 6.128px 18.385px 0 rgb(9 13 20 / 40%),
    -3.064px -3.064px 6.128px 0 rgb(235 239 245 / 4%),
    0 0.766px 0.766px 0 rgb(9 13 20 / 40%);
}

.card p {
  margin: 0;
}

.betaButton {
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 54px;
  margin-top: 10px;
  padding: 12px 24px;
  color: #fff;
  background: var(
    --default-button,
    linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%),
    #7ec17e
  );
  border-radius: 12px;
}
