.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
}

.zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: nowrap;
  background-color: #2e2d2f;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}

.share {
  width: auto;
  height: 100%;
  background-color: var(--color-surface-600);
  border: 0;
}

.newsButton{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 32px;
  padding: 8px;
  color: rgb(255 255 255 / 70%);
  background-color: var(--color-surface-600);
  border: 0;
}