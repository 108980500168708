
.coinsExchanges {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  margin: 4px 4px 0;
}

.itemCoinsExchanges {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: #1D1D1D;
}

.columnItems {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px 4px 0;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: #1D1D1D;

  &:last-child {
    border-bottom: none;
  }
}

.titleItem {
  color: #FFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}

.data {
  color: #FFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
}

.change {
  margin-left: 8px;
}

.stable {
  color: rgb(188 178 177 / 100%);
}

.decrease {
  color: #dc7474;
}

.increase {
  color: #84c47c;
}

.classNameOpen {
  flex-grow: unset;
  height: min-content;
  transition: height 0.5s;
}

.dominance {
  display: flex;
  gap: 10px;
}
