.tableViewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0 0;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 1024px) {
    max-width: 500px;
  }
}

.tableContainer {
  max-width: 1280px;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-moz-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.table {
  margin: auto;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.tableWrapper {
  position: relative;
  flex: 1;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.header {
  display: block;
  width: 100%;
  max-width: 1280px;
  margin: 0;
  margin-top: 30px;
  color: #fff;
  font-weight: 400;
  font-size: var(--title-1);
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 40px;
  text-align: center;
  background: #1d1f1e;

  @media (max-width: 1024px) {
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
  }
}

.subHeader {
  display: block;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #b0b0b0;
  font-weight: 400;
  font-size: var(--title-6);
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 30px;
  text-align: center;

  @media (max-width: 1200px) {
    text-align: center;
  }

  @media (max-width: 1024px) {
    width: 100% !important;
    min-height: 30px;
    margin-bottom: 20px;
  }
}

.controlBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 4px;

  &.column {
    flex-direction: column;
    align-items: flex-end;
  }

  .tableTypeSwitchContainer {
    display: flex;

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 1px solid hsl(0deg 0% 100% / 10%);
    }

    .tableTypeSwitch {
      z-index: 1;
      margin-bottom: -6px;
      padding: 10px 15px 15px;
      color: rgb(255 255 255 / 40%);
      font-size: var(--text-lead);
      line-height: 19px;
      cursor: pointer;

      @media (max-width: 1024px) {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      &.active {
        z-index: 11;
        margin-bottom: -3px;
        color: #85be85;
        border-bottom: 2px solid #85be85;

        @media (min-width: 1024px) {
          margin-bottom: -6px;
        }
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        width: 50%;
        margin-right: 10px;
        margin-bottom: -1px;
        margin-left: 10px;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .addCoins {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-size: var(--text-regular);
      font-family: Roboto, Arial, Helvetica, sans-serif;
      background: #373737;
      border-radius: 2px;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: 10px;
      }

      & .addIcon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        margin-left: 10px;
      }
    }

    &.watchList {
      display: flex;
      justify-content: space-between;
      padding-top: 4px;
      border-top: rgb(255 255 255 / 15%) solid 1px;
    }

    .watchlistTotals {
      padding-right: 8px;
      font-size: var(--text-lead);
      font-size: 14px;

      .watchlistTotalPrefix {
        color: #858585;
        line-height: 100%;
      }

      .watchlistTotalAmount {
        color: #b0b0b0;
        line-height: 19px;
      }

      & .increase {
        color: #85be85;
      }

      & .decrease {
        color: #dc7474;
      }

      & .stable {
        color: #b0b0b0;
      }
    }
  }

  .paginationContainerBottom {
    display: flex;
    justify-content: flex-end;
    justify-items: center;
    width: 100%;
    margin-top: 5px;
  }
}

.adLogo {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 10px;
}
