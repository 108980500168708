.editableAmount {
  width: 80px;
  height: 32px;
  padding-bottom: 3px;
  outline: none;
  border: none;
  background: inherit;
  color: #fff;
  cursor: pointer;
  text-align: right;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #1d1f1e;
  box-sizing: border-box;
  border-radius: 2px;

  &:hover {
    border-color: rgba(255, 255, 255, 0.25);
  }

  &:focus {
    border-color: #84c47c;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}
