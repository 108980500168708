.sortArrowContainer {
  display: flex;
  align-items: center;
  will-change: transform;
  visibility: hidden;
  fill: currentColor;
  background-color: var(--test);
  min-width: 16px;

  @media screen and (max-width: 1024px) {
    height: 16px;
  }

  &.positionRight {
    margin-left: 5px;

    @media screen and (max-width: 1024px) {
      margin-left: 1px;
    }
  }

  &.positionLeft {
    margin-right: 5px;

    @media screen and (max-width: 1024px) {
      margin-right: 1px;
    }
  }

  &.rotate {
    transform: rotate(180deg) !important;
    --webkit-transform: rotate(180deg) !important;
  }

  @media (max-width: 1024px) {
    height: 12px;
  }
}
