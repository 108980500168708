@use 'sass:math';

$breakpoints: (
  'sm': (
    max-width: 640px,
  ),
  'md': (
    max-width: 768px,
  ),
  'lg': (
    max-width: 1024px,
  ),
  'xl': (
    max-width: 1280px,
  ),
  '2xl': (
    max-width: 1536px,
  ),
) !default;
$app-font-size: 16;

@function px-to-rem($number) {
  @return math.div($number, $app-font-size) + rem;
}

@mixin number-lines($lines) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $lines > 1 {
    // postcss can't convert line-clamp
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    line-clamp: $lines;
  } @else {
    white-space: nowrap;
  }
}

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/* stylelint-disable property-no-unknown, selector-pseudo-class-no-unknown */
:export {
  app-font-size: $app-font-size;
  breakpoint: breakpoint;
  number-lines: number-lines;
}
