.rating {
  position: relative;

  .inactive {
    background-image: url('/assets/icons/icon-rating.svg');
    background-repeat: repeat-x;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .activeContainer {
    display: block;
    text-indent: -10000px;
    position: absolute;
    overflow: hidden;
  }

  .active {
    background-image: url('/assets/icons/icon-rating-active.svg');
    background-repeat: repeat-x;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-indent: 10000px;
    top: 0;
  }
}
