.tableHeader {
  position: sticky;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
  color: #858585;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  background: #1d1f1e;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 1024px) {
    top: 0;
    height: 40px;
    font-size: 12px;
  }
}

.watchlist {
  top: -1px !important;
}

.nftTable {
  top: -1px !important;
}


.tableHeaderScroll {
  position: sticky; /* Changed from fixed to sticky */
  top: 0;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  background: #1d1f1e; /* Ensure the background color is the same */
}

.tableHeaderScroll::-webkit-scrollbar {
  display: none;
}

.tableHeaderRow {
  display: table;
  width: 100%; /* Ensure the row takes full width */
  table-layout: fixed;
}

.tableHeaderCell {
  position: sticky;
  top: 0;
  z-index: 10;
  box-sizing: border-box;
  height: 40px;
  padding: 0 10px;
  font-weight: normal;
  white-space: nowrap;
  background: #333;
  background-color: #1d1f1e;
  border-top: 1px solid rgb(255 255 255 / 15%);
  border-bottom: 1px solid rgb(255 255 255 / 20%);
  cursor: pointer;
  
  .headerCellWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sortArrowContainer {
    visibility: hidden;
    fill: currentcolor;
  }

  @media (min-width: 1024px) {
    top: 40px;

    &:hover {
      color: rgb(133 133 133 / 40%);

      div {
        visibility: visible;
      }
    }
  }

  .activeCell {
    color: #fff;

    @media screen and (min-width: 1024px) {
      &:hover {
        color: rgb(255 255 255 / 70%);
      }
    }

    div {
      color: white;
      visibility: visible;
    }
  }

  .centerCell {
    justify-content: center;
  }

  .leftCell {
    justify-content: flex-start;
  }

  .rightCell {
    justify-content: flex-end;
  }

  .idCell {
    justify-content: center;
  }
}

.mobileCell {
  box-shadow: 4px 0 3px rgb(0 0 0 / 20%);
}
