.tableRow {
  z-index: 72;
  width: 100%;
  max-height: 40px;
  overflow: hidden;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  background: #1d1f1e;
  border-bottom: 0.5px solid hsl(0deg 0% 100% / 15%);
  text-rendering: optimizelegibility;

  @media screen and (max-width: 1024px) {
    height: 40px;
    font-size: 12px;
  }

  .tableCell {
    z-index: 9;
    box-sizing: border-box;
    height: 70px;
    padding: 16px 10px 12px;
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #1d1f1e;
    border-bottom: 0.5px solid rgb(255 255 255 / 15%);
    font-variant-numeric: tabular-nums !important;

    @media (max-width: 1024px) {
      height: 40px;
      padding: 5px;
    }
  }

  .centerCell {
    text-align: center;
  }

  .leftCell {
    text-align: left;
  }

  .rightCell {
    text-align: right;
  }

  .idCell {
    width: 5%;
    min-width: 5%;
    padding: 0;
  }

  .updateCell {
    padding: 6px 0 2px;

    @media (max-width: 1024px) {
      padding: 5px 0;
    }
  }

  .mobileCell {
    z-index: 4;
    padding: 6px 5px 0;
    box-shadow: 4px 0 3px rgb(0 0 0 / 20%);
  }
}

.symbolContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 0;

  &:hover {
    color: hsl(0deg 0% 100% / 80%);
    text-decoration: underline;
  }

  a {
    display: flex;
  }

  & .iconExchanges {
    display: flex;
    align-items: center;
    width: 32px;

    @media (max-width: 1024px) {
      width: 16px;
    }
  }
}

.symbolContainerMobile {
  padding-bottom: 0;

  a {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
    }
  }
}

.exchangeNameContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 8px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.symbolTypeContainer {
  margin-top: 3px;
  margin-left: 5px;
  padding: 2px 4px 1px;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  background: rgb(132 196 124 / 40%);
  border-radius: 2px;

  @media (max-width: 1024px) {
    padding: 1.5px 3px 1px;
    font-size: 8px;
    line-height: 9px;
  }

  &.decentralized {
    background: rgb(136 189 243 / 40%);
  }
}

.watchListNameContainer {
  display: flex;
  align-items: center;
}

.watchListNameStar {
  margin-right: 10px;
  cursor: pointer;
}

.watchListNameLink {
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .watchListIconContainer {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .watchListNameText {
    display: flex;
    flex-direction: column;

    .watchListName {
      width: 100px;
      overflow: hidden;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .watchListNameAbbr {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;

      @media (max-width: 1024px) {
        color: rgb(255 255 255 / 50%) !important;
        font-size: 11px;
        text-transform: capitalize;
      }
    }
  }

  .nameContainerMobile {
    display: flex;
    flex-direction: row;
  }
}

.tableUpdateRow {
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  animation-duration: 4s;

  @media (max-width: 1024px) {
    padding: 5px;
  }
}

.ratingCell {
  display: flex;
  justify-content: space-between !important;
  padding-left: 10px;
}

.pnLEmpty {
  color: #b0b0b0;
}

.pnL {
  display: flex;
  color: #fff;

  .pnLAbs {
    margin-right: 8px;
  }

  .pnLPercent {
    &.increase {
      color: #85be85;
    }

    &.decrease {
      color: #dc7474;
    }

    &.stable {
      color: #b0b0b0;
    }
  }
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: hsl(0deg 0% 100% / 80%);
    text-decoration: underline;
  }
}

.tableViewGreen {
  animation-name: table-view-green;
}

.tableViewRed {
  animation-name: table-view-red;
}

.mobileNameCell {
  color: hsl(0deg 0% 100% / 50%) !important;
  font-size: 11px;
  text-transform: capitalize;
}

.nftCollectionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nftImage {
  display: flex;
  min-width: 42px;
}

.nftImg {
  border-radius: 4px;
}

.nftNameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nftUrl {
  max-width: fill-available;
}

.nftName {
  margin: 0;
  overflow: hidden;
  color: rgb(255 255 255 / 90%);
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
}

.floorPriceName {
  display: flex;
  justify-content: flex-end;
}

.iconFloorPrice {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.iconFloorPriceEth {
  display: flex;
  justify-content: center;
}

.nftAddress {
  color: rgb(255 255 255 / 40%);
  font-size: 14px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

.flooPriceContainer {
  display: flex;
  flex-direction: column;
}

@keyframes table-view-green {
  0% {
    background-color: rgb(132 196 124 / 0%);
  }

  15% {
    background-color: rgb(132 196 124 / 40%);
  }

  100% {
    background-color: rgb(132 196 124 / 0%);
  }
}

@keyframes table-view-red {
  0% {
    background-color: rgb(220 116 116 / 0%);
  }

  15% {
    background-color: rgb(220 116 116 / 40%);
  }

  100% {
    background-color: rgb(220 116 116 / 0%);
  }
}

.buyDropdownButton {
  margin-left: 20px;
  padding: 2px 8px;
  color: #fff;
  font-size: 14px;
  background-color: #1d1f1e;
  border: 1px solid var(--color-primary-lightgreen);
  border-radius: 4px;
  cursor: pointer;
}

.buyDropdownContainer {
  position: absolute;
  margin-top: -14px;
}

.buyDropdownList {
  position: absolute;
  top: 120%;
  left: 20px;
  z-index: 1000; /* Ensure high enough within the dropdown container */
  margin: 0;
  padding: 8px;
  text-align: left;
  list-style: none;
  background-color: #333;
  border-radius: 4px;
}

.buyDropdownList li {
  padding: 8px 10px;
}

.buyDropdownList li a {
  margin: 0 5px;
  color: #fff;
  text-decoration: none;
}

.buyDropdownList li a:hover {
  color: var(--color-primary-lightgreen); /* Adjust to match your theme color */
}
