.header {
  font-size: 36px;
  color: white;
  margin-bottom: 32px;
  line-height: 50px;
  max-width: 900px;
  margin-top: 0;
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.container {
  max-width: 920px;
  padding: 24px 118px 32px 32px;
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 19px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  background: rgba(43, 43, 43, 0.6);
  border-radius: 2px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    padding: 12px 16px 24px;
  }

  section {
    > img {
      width: 100%;
      height: auto;
    }
    > p > span {
      color: #ffa723;
    }
    > a {
      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.content {
  font-size: 28px;
  font-weight: 700;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  line-height: 36px;
  color: white;
  margin-bottom: 16px;

  @media (max-width: 900px) {
    font-size: 24px;
  }
}

.note {
  width: 100%;
  padding: 16px 16px 12px;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  background: rgba(50, 51, 51, 0.7);
  border-radius: 2px;
  margin: 16px 0 24px;

  @media (mad-width: 900px) {
    margin-bottom: 16px;
  }

  > span {
    color: #ffa723;
  }
}

.link {
  cursor: pointer;
  color: #84c47c;
  text-decoration: none;
  @media screen and (min-width: 900px) {
    &:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
  }
}

.fact {
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-left: 1.5px solid white;
  box-sizing: border-box;
  padding-left: 12px;
}

.currency {
  > span {
    color: white;
  }
  > img {
    position: relative;
    top: 3px;
    margin: 0 2px;
  }
}

.highlitedtext {
  color: white;
  font-size: 24px;
  line-height: 32px;
}
